<div id="alert-settings" class="tw-pt-8 tw-px-6" rvSpinner="alert-loader" [rvShowSpinner]="alertsService.savingAlerts">
  <div class="border-round flex-row space-between p-4 mb-4">
    <label for="alerts-toggle-button" class="mb-0">Enable CAP Alerts</label>
    <mat-slide-toggle id="alerts-toggle" [checked]="alertsOn" (change)="toggleAlerts()"></mat-slide-toggle>
  </div>
  @if (featureAvailable === null) {
    <div class="alert alert-warning" id="alertsFeatureNeedsSubscription">
      <p class="u_remove-bottom"><i aria-hidden="true" class="fa fa-warning icon-left"></i>{{ WARNING_NOT_SUBSCRIBED }} <a class="madero-link" href="" uiSref="apps.purchase.home">Subscribe Now</a></p>
    </div>
  }
  @else if (!featureAvailable) {
    <div class="alert alert-warning" id="alertsFeatureUnavailable">
      <p class="u_remove-bottom"><i aria-hidden="true" class="fa fa-warning icon-left"></i>{{ WARNING_NOT_AVAILABLE }} <a class="madero-link" href="" (click)="showUpgradePlanModal()">Upgrade My Plan</a></p>
    </div>
  }
  @if (alertsService.missingTemplate && alertsService.alertSettings.templateProductCode === '') {
    <div class="alert alert-warning">
      <p class="u_remove-bottom"><i aria-hidden="true" class="fa fa-warning icon-left"></i>{{ WARNING_MISSING_TEMPLATE }}</p>
    </div>
  }
  <div id="errorBox" [shown]="alertsService.apiError" class="alert alert-danger" role="alert">
    <strong>{{alertsService.errorMessage}}</strong> {{alertsService.apiError}}
  </div>
  <div [shown]="alertsOn">
    <div class="border-round p-4 mb-4">
      <h5 class="mt-0">Provider Settings</h5>
      <div class="mb-4">
        <label class="control-label">Web Service URL:</label>
        <div class="flex-row space-between py-2 mb-4">
          <div>
            <span id="webserviceUrlInput" class="pr-1">
              {{ alertUrl }}
            </span>
            <a tabindex="0" class="u_clickable madero-link" (click)="copyAlertUrl()" (keyup.enter)="copyAlertUrl()">
              <streamline-icon ariaLabel="Copy alert URL" title="Copy" name="copy" width="15" height="15"></streamline-icon>
            </a>
          </div>
          <div><a tabindex="0" id="urlResetButton" class="madero-link" [ngClass]="{'disabled' : !featureAvailable}" href="#" (click)="resetAlertUrl()" (keyup.enter)="resetAlertUrl()">Reset</a></div>
        </div>
      </div>
      <div class="flex-row mb-2">
        <div class="flex-grow pr-3">
          <label class="control-label">Authentication Name:</label>
          <input id="usernameInput" type="text" class="form-control" [(ngModel)]="alertsService.alertSettings.userName">
        </div>
        <div class="flex-grow pl-3">
          <label class="control-label">Authentication Password:</label>
          <input id="passwordInput" type="password" class="form-control" [(ngModel)]="alertsService.alertSettings.password">
        </div>
      </div>
    </div>
    <div class="border-round p-4 mb-4">
      <h5 class="mt-0">Template</h5>
      <div class="row" [shown]="templates && templates.length">
        @for (template of templates; track template; let i = $index) {
          <div class="col-xs-12 col-sm-4 mb-4">
            <div class="border-round p-3 u_clickable" (click)="setTemplate(template.productCode)" [ngClass]="{ 'background-blue text-white': alertsService.alertSettings.templateProductCode === template.productCode }">
              <div class="template-image mb-3">
                @if (template.imageUrl) {
                  <img loading="lazy" [src]="template.imageUrl" alt="{{template.imageAlt || template.name}}">
                }
              </div>
              <mat-checkbox
                id="alert-template-{{ i }}"
                class="appear-enabled"
                [ngModel]="alertsService.alertSettings.templateProductCode === template.productCode"
                [disabled]="alertsService.alertSettings.templateProductCode === template.productCode"
                (ngModelChange)="setTemplate(template.productCode)">
              </mat-checkbox>
              <label class="u_clickable" for="alert-template-{{ i }}-input">{{ template.name }}</label>
            </div>
          </div>
        }
      </div>
      @if (alertsService.alertSettings.presentationId && !alertsService.alertSettings.templateProductCode || userStateService.isRiseAdmin()) {
        <div
          class="border-round p-3 flex-row"
          [ngClass]="{ 'background-blue text-white': alertsService.alertSettings.presentationId && !alertsService.alertSettings.templateProductCode }">
          <mat-checkbox
            id="custom-presentation"
            [ngModel]="alertsService.alertSettings.presentationId && !alertsService.alertSettings.templateProductCode"
            (ngModelChange)="setTemplate($event)">
          </mat-checkbox>
          <label class="u_clickable mb-0" for="custom-presentation-input">
            {{ alertsService.alertSettings.presentationName || "Custom Presentation" }}
          </label>
          <streamline-icon aria-hidden="true" [shown]="userStateService.isRiseAdmin() && !alertsService.alertSettings.presentationId" width="16" height="16" name="riseLogo" class="flex-row pl-2 rise-green"></streamline-icon>
          <button (click)="alertsService.changePresentation()" [shown]="userStateService.isRiseAdmin() && alertsService.alertSettings.presentationId" class="btn btn-default ml-4 rise-admin-only">
            Change
          </button>
        </div>
      }
    </div>
    <div class="border-round flex-row align-start p-4 mb-4">
      <h5 id="textToShowLabel" class="mt-2 w-25">Alert Content</h5>
      <div>
        @for (item of alertsService.textFieldsValues; track item) {
          <div>
            <mat-checkbox id="textFieldsCheckbox-{{ item.key }}" (change)="setCheckboxList('textFields', item.key, $event.checked)" [checked]="getCheckboxList('textFields', item.key)"></mat-checkbox>
            <label class="font-weight-normal u_nowrap mb-0 u_clickable" for="textFieldsCheckbox-{{ item.key }}-input">{{ item.label }}</label>
          </div>
        }
      </div>
    </div>
    <div class="border-round flex-row align-start p-4 mb-4">
      <h5 class="mt-2 w-25">Distribution</h5>
      <distribution-selector
        [(distribution)]="alertsService.alertSettings.distribution"
        [(distributeToAll)]="alertsService.alertSettings.distributeToAll"
        [(subcompanyDistribution)]="alertsService.alertSettings.subcompanyDistribution"
        [(distributeToSubcompanies)]="alertsService.alertSettings.distributeToSubcompanies"
        [enableSubcompanies]="true"
        [(subcompanyDistributeToAll)]="alertsService.alertSettings.subcompanyDistributeToAll"
        [enableSubcompanyDistributeToAll]="true"
        [enableFeatureCheck]="false"
        >
      </distribution-selector>
    </div>
    <div class="border-round flex-row p-4 mb-4">
      <h5 class="my-0 w-25">Default Expiry</h5>
      <div class="input-group">
        <input id="defaultExpiryInput" type="number" class="form-control" [(ngModel)]="alertsService.alertSettings.defaultExpiry">
        <span class="input-group-addon">
          <div class="u_nowrap">Minutes</div>
        </span>
      </div>
    </div>
    <div class="border-round mb-4">
      <mat-expansion-panel class="expansion-panel-flush mat-elevation-z0" [expanded]="showFilters">
        <mat-expansion-panel-header class="p-4 show-focus">
          <h5 class="my-0">Filters</h5>
        </mat-expansion-panel-header>
        <div class="panel-body" id="filtersContainer">
          <div class="row mb-2">
            <div class="form-group col-xs-12 col-sm-4" id="senderContainer">
              <label class="control-label">Sender:</label>
              <input type="text" class="form-control" [(ngModel)]="allowedSendersCsv" (ngModelChange)="parseCsvList(allowedSendersCsv, 'allowedSenders')">
            </div><!--form-group-->
            <div class="form-group col-xs-12 col-sm-4" id="handlingContainer">
              <label class="control-label">Handling:</label>
              <input type="text" class="form-control" [(ngModel)]="allowedHandlingCodesCsv" (ngModelChange)="parseCsvList(allowedHandlingCodesCsv, 'allowedHandlingCodes')">
            </div><!--form-group-->
            <div class="form-group col-xs-12 col-sm-4" id="codeContainer">
              <label class="control-label">Code:</label>
              <input type="text" class="form-control" [(ngModel)]="allowedEventCodesCsv" (ngModelChange)="parseCsvList(allowedEventCodesCsv, 'allowedEventCodes')">
            </div><!--form-group-->
          </div>
          <div class="row">
            <div class="col-xs-6 col-sm-3 mb-3">
              <label class="control-label" id="statusLabel">Status:</label>
              @for (item of alertsService.allowedStatusesValues; track item) {
                <div>
                  <mat-checkbox id="statusesCheckbox-{{ item.key }}" (ngModelChange)="setCheckboxList('allowedStatuses', item.key, $event)" [ngModel]="getCheckboxList('allowedStatuses', item.key)"></mat-checkbox>
                  <label class="font-weight-normal u_nowrap mb-0 u_clickable" for="statusesCheckbox-{{ item.key }}-input">{{ item.label }}</label>
                </div>
              }
            </div>
            <div class="col-xs-6 col-sm-3 mb-3">
              <label class="control-label" id="urgencyLabel">Urgency:</label>
              @for (item of alertsService.allowedUrgenciesValues; track item) {
                <div>
                  <mat-checkbox id="urgenciesCheckbox-{{ item.key }}" (ngModelChange)="setCheckboxList('allowedUrgencies', item.key, $event)" [ngModel]="getCheckboxList('allowedUrgencies', item.key)"></mat-checkbox>
                  <label class="font-weight-normal u_nowrap mb-0 u_clickable" for="urgenciesCheckbox-{{ item.key }}-input">{{ item.label }}</label>
                </div>
              }
            </div>
            <div class="col-xs-6 col-sm-3 mb-3">
              <label class="control-label" id="severetyLabel">Severity:</label>
              @for (item of alertsService.allowedSeveritiesValues; track item) {
                <div>
                  <mat-checkbox id="severitiesCheckbox-{{ item.key }}" (ngModelChange)="setCheckboxList('allowedSeverities', item.key, $event)" [ngModel]="getCheckboxList('allowedSeverities', item.key)"></mat-checkbox>
                  <label class="font-weight-normal u_nowrap mb-0 u_clickable" for="severitiesCheckbox-{{ item.key }}-input">{{ item.label }}</label>
                </div>
              }
            </div>
            <div class="col-xs-6 col-sm-3 mb-3">
              <label class="control-label" id="certaintyLabel">Certainty:</label>
              @for (item of alertsService.allowedCertaintiesValues; track item) {
                <div>
                  <mat-checkbox id="certaintiesCheckbox-{{ item.key }}" (ngModelChange)="setCheckboxList('allowedCertainties', item.key, $event)" [ngModel]="getCheckboxList('allowedCertainties', item.key)"></mat-checkbox>
                  <label class="font-weight-normal u_nowrap mb-0 u_clickable" for="certaintiesCheckbox-{{ item.key }}-input">{{ item.label }}</label>
                </div>
              }
            </div>
          </div>
          <br>
          <label class="control-label" id="categoryLabel">Category:</label>
          <div class="row">
            @for (item of alertsService.allowedCategoriesValues; track item) {
              <div class="col-xs-6 col-sm-3 flex-row align-start">
                <mat-checkbox id="categoriesCheckbox-{{ item.key }}" (ngModelChange)="setCheckboxList('allowedCategories', item.key, $event)" [ngModel]="getCheckboxList('allowedCategories', item.key)"></mat-checkbox>
                <label class="font-weight-normal mt-2 mb-0 u_clickable text-break-spaces" for="categoriesCheckbox-{{ item.key }}-input">{{ item.label }}</label>
              </div>
            }
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="border-round p-4 mb-4" [shown]="featureAvailable">
      <h5 class="mt-0">Terms &amp; Conditions</h5>
      <p id="termsText">The "CAP Alerts" Service is based on Common Alerting Protocol version 1.2. You are responsible for setting up your CAP Alerts
        provider and we highly recommend that you have your Alerts provider connect to our service with HTTPS, not HTTP, and that you require an Authentication
        Name and Password for that connection. You are also responsible for ensuring that your Displays have a stable internet connection for timely receipt of
        CAP Alerts, and conducting periodic testing according to your organization's policies. Rise Vision is not liable for any false, hacked, erroneous, missed,
      or delayed CAP Alerts.</p>
      <div class="form-group u_remove-bottom u_nowrap">
        <mat-checkbox id="termsAcceptedCheckbox" (ngModelChange)="termsAccepted = $event" [ngModel]="termsAccepted"></mat-checkbox>
        <label id="iAcceptLabel" class="mb-0 u_clickable" for="termsAcceptedCheckbox-input">I accept this Disclaimer and Acknowledgement</label>
      </div>
    </div>
    @if (featureAvailable === null) {
      <div class="alert alert-warning">
        <p class="u_remove-bottom"><i aria-hidden="true" class="fa fa-warning icon-left"></i>{{ WARNING_NOT_SUBSCRIBED }} <a class="madero-link" href="" uiSref="apps.purchase.home">Subscribe Now</a></p>
      </div>
    }
    @else if (!featureAvailable) {
      <div class="alert alert-warning">
        <p class="u_remove-bottom"><i aria-hidden="true" class="fa fa-warning icon-left"></i>{{ WARNING_NOT_AVAILABLE }} <a class="madero-link" href="" (click)="showUpgradePlanModal()">Upgrade My Plan</a></p>
      </div>
    }
    @if (alertsService.missingTemplate && alertsService.alertSettings.templateProductCode === '') {
      <div class="alert alert-warning">
        <p class="u_remove-bottom"><i aria-hidden="true" class="fa fa-warning icon-left"></i>{{ WARNING_MISSING_TEMPLATE }}</p>
      </div>
    }
    <div id="errorBox2" [shown]="alertsService.apiError" class="alert alert-danger" role="alert">
      <strong>{{alertsService.errorMessage}}</strong> {{alertsService.apiError}}
    </div>
  </div>
  <div class="button-row text-right" [shown]="alertsOn || showButtons">
    <p class="visible-xs"><last-modified [changeDate]="alertsService.changeDate" [changedBy]="alertsService.changedBy"></last-modified></p>
    <span class="hidden-xs u_margin-right"><last-modified [changeDate]="alertsService.changeDate" [changedBy]="alertsService.changedBy"></last-modified></span>
    <button id="cancelButton" uiSref="apps.displays.list" class="btn btn-default btn-toolbar">
      Cancel
    </button>
    <button
      *requireRole="'ua'"
      id="saveButton"
      type="submit"
      class="btn btn-success btn-toolbar"
      (click)="saveSettings()"
      [disabled]="alertsService.savingAlerts || !featureAvailable || !termsAccepted && alertsOn || alertsService.missingTemplate && alertsService.alertSettings.templateProductCode === ''">
      {{ alertsService.savingAlerts ? 'Saving' : 'Save' }}
    </button>
  </div>
</div><!--container-->
