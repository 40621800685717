import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UIRouterGlobals } from '@uirouter/angular';

import { MatDialog } from '@angular/material/dialog';
import { HelpWidgetFactory } from 'src/app/ajs-upgraded-providers';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { PlayerLicenseService } from 'src/app/displays/services/player-license.service';
import { ScheduleService } from 'src/app/schedules/services/schedule.service';
import { DisplayService } from '../../services/display.service';
import { DisplayPreviewComponent } from '../display-preview/display-preview.component';
import { ActionsMenu } from 'src/app/components/common-actions/common-actions.component';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'display-add',
  templateUrl: './display-add.component.html',
  styleUrls: ['./display-add.component.scss']
})
export class DisplayAddComponent implements OnInit {

  selectedSchedule = null;
  actions: ActionsMenu;

  @ViewChild('displayForm')
  public displayForm: NgForm;

  get hasActivationKey(): boolean {
    return !!this.uiRouterGlobals.params.activationKey;
  }

  get isActivating(): boolean {
    return this.uiRouterGlobals.current.name === 'apps.displays.activate';
  }

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private uiRouterGlobals: UIRouterGlobals,
    public displayService: DisplayService,
    public playerLicenseService: PlayerLicenseService,
    private scheduleService: ScheduleService,
    private currentPlanService: CurrentPlanService,
    public helpWidgetFactory: HelpWidgetFactory,
    public dialog: MatDialog,
    private stateService: StateService,
  ) {

    this.scheduleService.getAllDisplaysSchedule()
      .then((result) => {
        if (result) {
          this.selectedSchedule = result;
        }
      });
  }

  ngOnInit() {
    this.actions = {
      saveAction: {
        id: 'saveButton',
        label: 'Save',
        action: () => this.save(),
        requireRole: 'da',
        disabled: this.displayForm?.invalid
      },
      cancelAction: {
        id: 'cancelButton',
        label: 'Cancel',
        action: () => this.stateService.go('apps.displays.list')
      }
    };

    if (!this.playerLicenseService.isProAvailable(this.displayService.display)) {
      this.currentPlanService.confirmAndPurchase();
    }
  }

  save() {
    if (!this.displayForm.valid) {
      console.info('form not valid');
      return;
    }

    if (this.displayService.display.activationKey) {
      this.displayService.display.activationKey = this.displayService.display.activationKey.toUpperCase();
    }

    this.displayService.addDisplay(this.selectedSchedule);
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  showPreview() {
    const dialogRef = this.dialog.open(DisplayPreviewComponent, {
      data: {
        selectedSchedule: this.selectedSchedule
      },
      hasBackdrop: false,
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      panelClass: "u_remove-radius"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.id) {
        this.selectedSchedule = result;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

}