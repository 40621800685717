import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, from, map, Observable, of } from 'rxjs';

import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { DisplaySummary, DisplaySummaryService } from 'src/app/displays/services/display-summary.service';
import { PlayerLicenseService } from 'src/app/displays/services/player-license.service';

@Component({
  selector: 'display-license-counts',
  templateUrl: './display-license-counts.component.html',
  styleUrls: ['./display-license-counts.component.scss']
})
export class DisplayLicenseCountsComponent implements OnInit {

  @Input() showOnlineCount: boolean;
  @Input() countOnly: boolean;

  protected items: Observable<DisplayCounts>;

  get requiredLicenses(): number {
    let required = 0;
    if (this.displaySummaryService.summary && this.displaySummaryService.summary.scheduledUnlicensed) {
      required = this.displaySummaryService.summary.scheduledUnlicensed - this.playerLicenseService.getProAvailableLicenseCount();
    }
    return required;
  }

  constructor(
    public playerLicenseService: PlayerLicenseService,
    public currentPlanService: CurrentPlanService,
    public displaySummaryService: DisplaySummaryService
  ) { }

  ngOnInit(): void {
    this.items = this.getDisplayCounts();
  }

  purchaseLicenses() {
    if (this.requiredLicenses) {
      this.currentPlanService.showPurchaseOptions(this.requiredLicenses);
    }
  }

  private getDisplayCounts(): Observable<DisplayCounts> {
    return forkJoin([
      this.getDisplaySummary(this.displaySummaryService),
      this.getDisplayLicenses(
        this.playerLicenseService.getProUsedLicenseCount(),
        this.currentPlanService.isUnlimitedPlan(),
        this.playerLicenseService.getProAvailableLicenseCount(),
        this.playerLicenseService.hasProfessionalLicenses()
      )
    ]).pipe(
      map(this.buildCounts)
    );
  }

  private getDisplayLicenses(
    proUsedLicenseCount: number,
    isUnlimitedPlan: boolean,
    proAvailableLicenseCount: number,
    hasProfessionalLicenses: boolean
  ): Observable<{ used: number, available: string }> {
    return of({
      used: proUsedLicenseCount,
      available: isUnlimitedPlan
        ? hasProfessionalLicenses ? 'Unlimited' : ''
        : proAvailableLicenseCount + ' Available'
    });
  }

  private getDisplaySummary(
    service: DisplaySummaryService
  ): Observable<DisplaySummary> {
    return from(service.loadSummary())
    .pipe(map(() => service.summary));
  }

  private buildCounts(
    [summary, licenses]: [DisplaySummary, { used: number, available: string }]
  ): DisplayCounts {
    return [
      { icon: 'shield-tick', iconClass: 'blue', text: `${licenses.used} Licensed`, subtext: licenses.available },
      { icon: 'wifi', iconClass: 'green', text: `${summary.online} Online`, subtext: `${summary.offline} Offline` },
      ...(summary.scheduledUnlicensed
        ? [{ icon: 'alert-triangle', iconClass: 'orange', text: `${summary.scheduledUnlicensed} Scheduled`, subtext: 'Unlicensed Displays' }]
        : []
      ),
    ];
  }

}

type DisplayCounts = Array<{
  icon: string;
  iconClass: string;
  text: string;
  subtext: string;
}>;
