import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ScheduleService } from '../../services/schedule.service';
import { ScheduleActionsService } from '../../services/schedule-actions.service';
import { Transition } from '@uirouter/angularjs';
import { ModalService } from 'src/app/components/modals/modal.service';

@Component({
  selector: 'schedule-details',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.scss']
})
export class ScheduleDetailsComponent {

  @ViewChild('scheduleForm')
  public scheduleForm: NgForm;

  actions = this.scheduleActionsService.getActions();

  constructor(public scheduleService: ScheduleService,
    private modalService: ModalService,
    private transition: Transition,
    private scheduleActionsService: ScheduleActionsService
  ) {
    this.actions.saveAction = {
      id: 'saveButton',
      label: 'Save',
      action: () => this.save(),
      disabled: this.scheduleForm?.invalid
    };
    this.actions.deleteAction = {
      id: 'deleteButton',
      label: 'Delete',
      action: () => this.confirmDelete(),
      requireRole: 'cp'
    };
    this.actions.moreOptions.splice(0, 0, {
      id: 'newScheduleButton',
      label: 'New Schedule',
      requireRole: 'cp',
      action: () => this.addSchedule()
    });
  }

  addSchedule() {
    if (!this.scheduleForm.dirty) {
      this.transition.router.stateService.go('apps.schedules.add');
    } else {
      this.modalService.confirm('Unsaved Changes',
          'You have unsaved changes. Do you want to Save or Discard them?',
          'Save', 'Discard')
      .then(() => {
        // do what you need if user presses ok
        this.save()
          .then(() => {
            this.transition.router.stateService.go('apps.schedules.add');
          });
      })
      .catch((value) => {
        // do what you need to do if user cancels
        if (value) {
          this.transition.router.stateService.go('apps.schedules.add');
        }
      });
    }
  }

  save() {
    if (!this.scheduleForm.valid) {
      console.info('form not valid: ', this.scheduleForm.errors);

      return Promise.reject();
    } else {
      return this.scheduleService.updateSchedule();
    }
  }

  confirmDelete() {
    this.modalService.confirm('Delete Schedule?',
        'Are you sure you want to delete this Schedule?',
        'Yes', 'No')
      .then(() => {
        this.scheduleService.deleteSchedule();
      });
  }
}
