<div id="company-users" class="tw-pt-8 tw-px-6">
  <div id="errorBox" [shown]="users.apiError" class="alert alert-danger text-danger" role="alert">
    <strong>{{users.errorMessage}}</strong> {{users.apiError}}
  </div>

  <!-- Admin View with Tabs -->
  <div *requireRole="'ua'">
    <mat-tab-group #TabGroup [(selectedIndex)]="selectedTabIndex" class="user-tabs tw-w-full tw-p-2 tw-pt-0 tw-border-none">
      <!-- Users Tab -->
      <mat-tab label="Users">
        <div class="w-100 tw-mt-8">
          <ng-container *ngTemplateOutlet="userListContent"></ng-container>
        </div>
      </mat-tab>

      <!-- SSO Settings Tab -->
      <mat-tab label="SSO Settings">
        <sso-settings (cancelEvent)="cancel()"></sso-settings>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- Non-Admin View -->
  <div class="w-100" *requireRole="'!ua'">
    <ng-container *ngTemplateOutlet="userListContent"></ng-container>
  </div>
</div>

<!-- Reusable User List Template -->
<ng-template #userListContent>
  <div class="tw-flex button-toolbar-md-folded">
    <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="users.doSearch"
     ></search-filter>
    <div class="ml-md-3 button-toolbar tw-flex" *requireRole="'ua sa'">
      <a href="#" *requireRole="'sa'" (click)="exportUsers()"
        class="btn btn-default btn-toolbar rise-admin-only">Export</a>
      <a id="userAddButton" class="btn btn-primary btn-toolbar-wide" uiSref="apps.user.add">
        Add User
      </a>
    </div>
  </div>

  <!-- List of Users -->
  <div class="scrollable-list tw-mt-8 tw-max-h-[calc(100vh-300px)]" scrollingList
    (scrollEndEvent)="users.load()" rvSpinner="company-users-list" [rvShowSpinner]="users.loadingItems">
    <table class="table">
      <thead class="table-header">
        <tr class="table-header__row">
          <th class="table-header__cell u_clickable">Name</th>
        </tr>
      </thead>
      <tbody class="table-body">
        @for (user of users.items.list; track user) {
        <tr tabindex="0" class="table-body__row company-users-list-item"
          (keyup.enter)="goToUserDetails(user.username)" (click)="goToUserDetails(user.username)">
          <td class="table-body__cell u_clickable">
            <p class="list-group-item-text">
              <strong>{{user.firstName}} {{user.lastName}}</strong>
              <small class="text-muted ml-2">{{user.email}}</small>
            </p>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</ng-template>